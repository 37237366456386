<template>
  <!-- BEGIN: Content-->
  <div class="card">
    <div
      v-b-toggle="`collapseAmountGrant`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Amount of the {{ grant.type.name }}</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="grant.type.type === 'in_kind'"
      class="mt-1 ms-2 mb-2"
    >
      <div class="form-check form-check-success form-switch d-flex align-items-center">
        <input
          id="publishContent"
          v-model="grant.cant_quantify"
          type="checkbox"
          class="form-check-input"
        >
        <label
          class="form-check-label"
          for="publishContent"
        ><small class="ms-1">Can't quantify</small></label>
      </div>
    </div>
    <b-collapse
      v-if="(!grant.cant_quantify && grant.type.type === 'in_kind') || grant.type.type !== 'in_kind'"
      :id="`collapseAmountGrant`"
      visible
    >
      <div class="card-content collapse show">
        <div class="card-body">
          <p>Please, always gross figures (including overheads).</p>
          <div class="row">
            <div class="col-md-4">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Total amount of the grant *</label>
                <div class="input-group">
                  <input
                    v-model="grant.total_amount"
                    type="text"
                    step="0.01"
                    class="form-control"
                    placeholder="0,00"
                    @change="formatAllFloatsTotalAmounts"
                    @input="updateInput(1)"
                  > <!-- poner máscara numérica con dos decimales -->
                  <span
                    id="basic-addon2"
                    class="input-group-text"
                  >€</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Total amount of the grant given to your institution *</label>
                <div class="input-group">
                  <input
                    v-model="grant.total_amount_institution"
                    type="text"
                    class="form-control"
                    step="0.01"
                    placeholder="0,00"
                    aria-label="0,00"
                    aria-describedby="basic-addon2"
                    @change="formatAllFloatsTotalAmounts"
                    @input="updateInput(2)"
                  > <!-- poner máscara numérica con dos decimales -->
                  <span
                    id="basic-addon2"
                    class="input-group-text"
                  >€</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Total amount of the grant given to your group / research unit *</label>
                <div class="input-group">
                  <input
                    v-model="grant.total_amount_group"
                    type="text"
                    class="form-control"
                    step="0.01"
                    placeholder="0,00"
                    aria-label="0,00"
                    aria-describedby="basic-addon2"
                    @change="formatAllFloatsTotalAmounts"
                  > <!-- poner máscara numérica con dos decimales -->
                  <span
                    id="basic-addon2"
                    class="input-group-text"
                  >€</span>
                </div>
              </div>
            </div>
          </div>

          <AmountGrantTable v-if="!loading" />
        </div>
      </div>
    </b-collapse>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import AmountGrantTable from './AmountGrantTable.vue'

export default {
  components: {
    BCollapse,
    AmountGrantTable,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      grant: 'grants/item',
    }),
  },
  async mounted() {
    this.formatFloat(this.grant.total_amount, 'total_amount')
    this.formatFloat(this.grant.total_amount_institution, 'total_amount_institution')
    this.formatFloat(this.grant.total_amount_group, 'total_amount_group')

    this.loading = false
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    updateInput(input) {
      if (input === 1) {
        this.grant.total_amount_institution = this.grant.total_amount
        this.grant.total_amount_group = this.grant.total_amount
      } else if (input === 2) {
        this.grant.total_amount_group = this.grant.total_amount_institution
      }
    },
    formatAllFloatsTotalAmounts() {
      if (this.grant.total_amount) {
        this.formatFloat(this.grant.total_amount, 'total_amount')
      }

      if (this.grant.total_amount_institution) {
        this.formatFloat(this.grant.total_amount_institution, 'total_amount_institution')
      }

      if (this.grant.total_amount_group) {
        this.formatFloat(this.grant.total_amount_group, 'total_amount_group')
      }
    },
    formatFloat(float, property) {
      if (float) {
        const value = float.toString().replace(/[^\d.]/g, '') // Remove non-numeric characters
        const parts = value.split('.') // Split into integer and decimal parts

        // Add commas as thousands separators in the integer part
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        // Reconstruct the formatted value
        const formattedValue = parts.join('.')

        // Update the input value
        this.grant[property] = formattedValue
      }
    },
  },
}
</script>
